import { useState } from "react";
import PropTypes from "prop-types";
import { ModalOverlay, ModalCloseButton, ModalContent } from "@chakra-ui/react";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";

const SendPresentModal = ({ currentAccount }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        address: "",
        message: "",
        gift: null,
    });

    const renderStep = () => {
        if (currentStep === 1) {
            return (
                <FormStep1
                    setCurrentStep={setCurrentStep}
                    formData={formData}
                    setFormData={setFormData}
                />
            );
        }

        if (currentStep === 2) {
            return (
                <FormStep2
                    currentAccount={currentAccount}
                    setCurrentStep={setCurrentStep}
                    formData={formData}
                    setFormData={setFormData}
                />
            );
        }

        if (currentStep === 3) {
            return (
                <FormStep3
                    currentAccount={currentAccount}
                    setCurrentStep={setCurrentStep}
                    formData={formData}
                    setFormData={setFormData}
                />
            );
        }
    };

    return (
        <>
            <ModalOverlay />
            <ModalContent border="5px solid">
                {renderStep()}
                <ModalCloseButton />
            </ModalContent>
        </>
    );
};

SendPresentModal.propTypes = {
    currentAccount: PropTypes.string,
};

export default SendPresentModal;
