import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Heading,
    Text,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Link,
} from "@chakra-ui/react";
import { CheckIcon, WarningIcon } from "@chakra-ui/icons";
import { ethers } from "ethers";
import nftySanta from "../contracts/NftySanta.json";
import IERC721 from "../contracts/IERC721.json";

const FormStep3 = ({
    currentAccount,
    setCurrentStep,
    formData,
    setFormData,
}) => {
    const [contract, setContract] = useState();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("Approve Santa Contract");
    const [errorMessage, setErrorMessage] = useState();
    const [approved, setApproved] = useState(false);
    const [sent, setSent] = useState(false);
    const [price, setPrice] = useState();
    const [onNiceList, setOnNiceList] = useState(false);
    const [mintedTokenId, setMintedTokenId] = useState();

    const CONTRACT_ADDRESS = process.env.GATSBY_CONTRACT_ADDRESS;

    // Setup contract
    useEffect(async () => {
        if (currentAccount) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                CONTRACT_ADDRESS,
                nftySanta.abi,
                signer
            );
            setContract(contract);

            try {
                const isOnNiceList = await contract.isOnNiceList();
                if (isOnNiceList) {
                    setPrice("0");
                    setOnNiceList(true);
                } else {
                    const priceToSend = await contract.getPresentPrice();
                    const ethPriceString =
                        ethers.utils.formatEther(priceToSend);
                    setPrice(ethPriceString);
                }
            } catch (error) {
                setErrorMessage(error.message);
            }
        }
    }, [currentAccount]);

    // Setup listener
    useEffect(() => {
        const onPresentSent = (tokenId) => {
            setLoading(false);
            setSent(true);
            setMintedTokenId(tokenId);
        };

        if (contract) {
            contract.on("PresentSent", onPresentSent);
        }

        return () => {
            if (contract) {
                contract.off("PresentSent", onPresentSent);
            }
        };
    }, [contract]);

    const resetForm = () => {
        setFormData({});
        setCurrentStep(1);
    };

    const retry = () => {
        setErrorMessage("");

        if (approved) {
            sendPresent();
        } else {
            approve();
        }
    };

    const approve = async () => {
        setLoading(true);
        setStatus("Approve Santa");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const giftContractAddress = formData.gift.asset_contract.address;
        const giftContract = new ethers.Contract(
            giftContractAddress,
            IERC721.abi,
            signer
        );

        giftContract.once("Approval", (_owner, isApproved, _tokenId) => {
            setApproved(isApproved);
            sendPresent();
        });

        try {
            await giftContract.approve(
                contract.address,
                formData.gift.token_id
            );
            setStatus("Approving Santa, please wait...");
        } catch (error) {
            setLoading(false);
            setStatus("");
            setErrorMessage(error.message);
        }
    };

    const sendPresent = async () => {
        setLoading(true);
        setStatus("Confirm Send");
        try {
            const value = ethers.utils.parseEther(price);
            await contract.sendPresent(
                formData.gift.asset_contract.address,
                formData.gift.token_id,
                formData.message,
                formData.address,
                { value }
            );
            setStatus("Sending Present, please wait...");
        } catch (error) {
            setLoading(false);
            setStatus("");
            setErrorMessage(error.message);
        }
    };

    return (
        <>
            <ModalHeader fontWeight="normal">
                <Text fontSize="sm" color="gray.500">
                    Step 3
                </Text>
                <Heading textTransform="uppercase">Wrap and Send</Heading>
                {onNiceList ? (
                    <Text fontSize="2xl">
                        You&apos;re on the nice list! Send your gift for free.
                    </Text>
                ) : (
                    <Text fontSize="2xl">
                        Pay {price} eth and send your gift
                    </Text>
                )}
            </ModalHeader>

            <ModalBody fontFamily="mono">
                <Text mb={8}>
                    There will be two actions to confirm. The first will approve
                    Santa to hold your NFT and the second will wrap and send the gift to
                    your recipient.
                </Text>

                {loading && (
                    <>
                        <Spinner
                            mr={4}
                            size="lg"
                            emptyColor="gray.200"
                            color="red.500"
                            verticalAlign="middle"
                        />{" "}
                        {status}
                    </>
                )}

                {errorMessage && (
                    <>
                        <Box color="red.500">
                            <WarningIcon /> An error has occured. See below for
                            more details:
                            <Box
                                background="gray.200"
                                color="black"
                                p={2}
                                borderRadius={2}
                                my={4}
                            >
                                {errorMessage}
                            </Box>
                        </Box>
                        <Button colorScheme="red" onClick={retry}>
                            Retry
                        </Button>
                    </>
                )}

                {!loading && !errorMessage && !approved && (
                    <Button colorScheme="red" onClick={approve} mb={4}>
                        Approve Santa
                    </Button>
                )}

                {sent && (
                    <Text color="green.500">
                        <CheckIcon /> 🙌 Your present has been sent! You can see it <Link isExternal textDecor="underline" href={`${process.env.GATSBY_OPENSEA_URL+CONTRACT_ADDRESS}/${mintedTokenId}`}>here</Link>
                    </Text>
                )}
            </ModalBody>

            <ModalFooter>
                <Button
                    variant="ghost"
                    mr={4}
                    onClick={() => setCurrentStep(2)}
                    isDisabled={loading || sent}
                >
                    Back
                </Button>
                <Button
                    isFullWidth
                    colorScheme="red"
                    onClick={resetForm}
                    isDisabled={!sent}
                >
                    Send another
                </Button>
            </ModalFooter>
        </>
    );
};

FormStep3.propTypes = {
    currentAccount: PropTypes.string.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
};

export default FormStep3;
