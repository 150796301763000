import PropTypes from "prop-types";
import {
    Heading,
    Text,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@chakra-ui/react";

const FormStep1 = ({ setCurrentStep, formData, setFormData }) => {
    return (
        <>
            <ModalHeader fontWeight="normal">
                <Text fontSize="sm" color="gray.500">
                    Step 1
                </Text>
                <Heading textTransform="uppercase">Write your tag</Heading>
                <Text fontSize="2xl">
                    Who would you like to send your nft to?
                </Text>
            </ModalHeader>
            <ModalBody>
                <FormControl id="address" mb={4}>
                    <FormLabel fontSize="xl">Eth address or ens name</FormLabel>
                    <Input
                        value={formData.address}
                        fontFamily="mono"
                        placeholder="e.g. mum.eth"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                address: e.target.value,
                            })
                        }
                    />
                </FormControl>
                <FormControl id="message" mt={4}>
                    <FormLabel fontSize="xl">Message</FormLabel>
                    <Textarea
                        value={formData.message}
                        resize="none"
                        fontFamily="mono"
                        maxLength="280"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                message: e.target.value,
                            })
                        }
                        placeholder="Merry Christmas you filthy animal."
                    />
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button
                    colorScheme="red"
                    isFullWidth
                    mt={8}
                    onClick={() => setCurrentStep(2)}
                    isDisabled={!(formData.address && formData.message)}
                >
                    Next: Choose your NFT
                </Button>
            </ModalFooter>
        </>
    );
};

FormStep1.propTypes = {
    setCurrentStep: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
};

export default FormStep1;
