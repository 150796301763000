import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Grid,
    Box,
    Heading,
    Text,
    Image,
    FormControl,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@chakra-ui/react";

const FormStep2 = ({
    currentAccount,
    setCurrentStep,
    formData,
    setFormData,
}) => {
    const openseaApi = process.env.GATSBY_OPENSEA_API_URL;
    const [assets, setAssets] = useState([]);

    // Get NFTs
    useEffect(() => {
        if (currentAccount) {
            fetch(`${openseaApi}/assets?owner=${currentAccount}`)
                .then((res) => res.json())
                .then((result) => {
                    setAssets(result.assets);
                });
        }
    }, [currentAccount]);

    return (
        <>
            <ModalHeader fontWeight="normal">
                <Text fontSize="sm" color="gray.500">
                    Step 2
                </Text>
                <Heading textTransform="uppercase">Choose your NFT</Heading>
                <Text fontSize="2xl">Select an nft you own</Text>
                <Text fontSize="sm" fontFamily="mono" color="gray.500" mb={4}>
                    Nfts owned by {currentAccount.slice(0, 5)}...
                    {currentAccount.slice(-4)} (loaded from the Opensea Api)
                </Text>
            </ModalHeader>

            <ModalBody>
                <FormControl id="gift">
                    {formData.gift && (
                        <Box
                            border="1px"
                            borderColor="gray.200"
                            p={2}
                            maxW="250px"
                        >
                            <Image
                                src={formData.gift.image_thumbnail_url}
                                fallbackSrc="https://via.placeholder.com/150?text=No+image+set"
                                objectFit="cover"
                                width="100%"
                            />
                            <Text fontSize="sm" fontFamily="mono" isTruncated>
                                <b>{formData.gift.collection.name}</b> <br />#
                                {formData.gift.token_id}
                            </Text>
                            <Button
                                mt={2}
                                variant="outline"
                                isFullWidth
                                onClick={() =>
                                    setFormData({ ...formData, gift: null })
                                }
                            >
                                Change Present
                            </Button>
                        </Box>
                    )}

                    {!formData.gift && (
                        <Grid templateColumns="repeat(auto-fit, minmax(120px, 1fr))">
                            {assets.map((value, index) => {
                                return (
                                    <Box
                                        key={index}
                                        border="1px"
                                        borderColor="gray.200"
                                        p={2}
                                    >
                                        <Image
                                            src={value.image_thumbnail_url}
                                            fallbackSrc="https://via.placeholder.com/100?text=No+image+set"
                                            objectFit="cover"
                                            width="100%"
                                            height="100px"
                                        />
                                        <Text
                                            fontSize="sm"
                                            fontFamily="mono"
                                            isTruncated
                                        >
                                            <b>{value.collection.name}</b>{" "}
                                            <br />#{value.token_id}
                                        </Text>
                                        <Button
                                            variant="outline"
                                            mt={2}
                                            isFullWidth
                                            onClick={() =>
                                                setFormData({
                                                    ...formData,
                                                    gift: value,
                                                })
                                            }
                                        >
                                            Select
                                        </Button>
                                    </Box>
                                );
                            })}
                        </Grid>
                    )}
                </FormControl>
            </ModalBody>

            <ModalFooter>
                <Button
                    variant="outline"
                    mr={4}
                    onClick={() => setCurrentStep(1)}
                >
                    Back
                </Button>
                <Button
                    colorScheme="red"
                    isFullWidth
                    onClick={() => setCurrentStep(3)}
                    isDisabled={!formData.gift}
                >
                    Next: Wrap + Send
                </Button>
            </ModalFooter>
        </>
    );
};

FormStep2.propTypes = {
    currentAccount: PropTypes.string.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
};

export default FormStep2;
