import { useEffect, useState } from "react";
import {
    keyframes,
    Link,
    Box,
    List,
    ListItem,
    Heading,
    Text,
    Button,
    useDisclosure,
    Modal,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { WarningIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import SendPresentModal from "../components/SendPresentModal";
import { Helmet } from "react-helmet";
import facebookImage from "../images/facebook.png"
import twitterImage from "../images/twitter.png"


const IndexPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [walletAvailable, setWalletAvailable] = useState();
    const [currentAccount, setCurrentAccount] = useState();
    const [errorMessage, setErrorMessage] = useState("");

    const metaTitle = "Gift an NFT this Christmas";
    const metaDescription = "Write the tag. Wrap your NFT. Send. Quickly and easily gift one of your NFTs this Christmas.";
    const twitterName = "NftySanta";

    const fadeIn = keyframes`
    from {opacity: 0;}
    to {opacity: 1}
  `;

    const checkIfWalletIsConnected = async () => {
        try {
            const { ethereum } = window;

            if (!ethereum) {
                setWalletAvailable(false);
                return;
            } else {
                setWalletAvailable(true);
                const accounts = await ethereum.request({
                    method: "eth_accounts",
                });

                ethereum.on("accountsChanged", (accountsChanged) => {
                    setCurrentAccount(accountsChanged[0]);
                });

                if (accounts.length !== 0) {
                    setCurrentAccount(accounts[0]);
                }
            }
        } catch (error) {
            setErrorMessage(error);
        }
    };

    const connectWallet = async () => {
        try {
            const { ethereum } = window;

            const accounts = await ethereum.request({
                method: "eth_requestAccounts",
            });

            setCurrentAccount(accounts[0]);
            onOpen();
        } catch (error) {
            setErrorMessage(error);
        }
    };

    useEffect(() => {
        checkIfWalletIsConnected();
    }, []);

    const renderButton = () => {
        if (!walletAvailable) {
            return (
                <Link
                    href="https://metamask.io/"
                    isExternal
                    textDecoration="none"
                >
                    <Button
                        variant="outline"
                        opacity={0}
                        animation={`${fadeIn} 1s ease 4s forwards`}
                    >
                        Install Metamask or similar to continue{" "}
                        <ExternalLinkIcon ml={2} />
                    </Button>
                </Link>
            );
        } else {
            if (currentAccount) {
                return (
                    <Button
                        variant="outline"
                        onClick={onOpen}
                        opacity={0}
                        animation={`${fadeIn} 1s ease 4s forwards`}
                    >
                        Send a present
                    </Button>
                );
            } else {
                return (
                    <Button
                        variant="outline"
                        onClick={connectWallet}
                        opacity={0}
                        animation={`${fadeIn} 1s ease 4s forwards`}
                    >
                        Connect wallet to get started
                    </Button>
                );
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href="https://nftsanta.com" />
                <meta name="icon" href="../images/nft-present.png" />
                {/* Analytics */}
                <script defer data-domain="nftysanta.com" src="https://plausible.io/js/plausible.js"></script>

                {/* Facebook OG Tags */}
                <meta property="og:url" content="https://nftysanta.com" />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />  
                <meta property="og:image" content={`https://nftysanta.com${facebookImage}`} />              
                <meta property="og:type" content="website" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:width" content="630" />

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:creator" content={`@${twitterName}`} />
                <meta name="twitter:image" content={`https://nftysanta.com${twitterImage}`} />
            </Helmet>

            <Box
                pos="absolute"
                height="100%"
                minH="100vh"
                width="100%"
                bgGradient="linear(to-b, black, gray.800)"
                zIndex={-1}
            >
                <StaticImage
                    src="../images/bauble.jpg"
                    alt=""
                    loading="eager"
                    layout="fullWidth"
                    style={{
                        opacity: "0.4",
                        height: "100%",
                    }}
                    formats={["auto", "webp", "avif"]}
                />
            </Box>

            <Box pt={36} pl={8} color="white" maxW="800px">
                <Heading
                    opacity={0}
                    animation={`${fadeIn} 1.5s ease 1s forwards`}
                    fontSize={{ base: "48px", md: "78px" }}
                    as="h1"
                    textTransform="uppercase"
                    fontWeight="500"
                    letterSpacing="5px"
                >
                    Gift an NFT this Christmas
                </Heading>

                <Box opacity={0} animation={`${fadeIn} 1.5s ease 2s forwards`}>
                    <StaticImage
                        src="../images/nft-present.png"
                        alt="NFT Christmas Present"
                        loading="eager"
                        placeholder="blurred"
                        width={180}
                        formats={["auto", "webp", "avif"]}
                        style={{
                            display: "inline-block",
                        }}
                    />
                </Box>

                <List mb={4} fontSize={24}>
                    <ListItem
                        opacity={0}
                        animation={`${fadeIn} 1.5s ease 2s forwards`}
                    >
                        Write your tag. Wrap. Send.
                    </ListItem>
                    <ListItem
                        opacity={0}
                        animation={`${fadeIn} 1s ease 3s forwards`}
                    >
                        Can&apos;t be opened until Christmas Day.
                    </ListItem>
                    <ListItem
                        opacity={0}
                        animation={`${fadeIn} 1s ease 4s forwards`}
                    >
                        Only 0.01 eth to send.
                    </ListItem>
                </List>

                {renderButton()}

                {errorMessage && (
                    <Box mt={4}>
                        <WarningIcon /> Error connecting to wallet. See below:
                        <Box
                            background="gray.200"
                            color="black"
                            p={2}
                            borderRadius={2}
                            my={4}
                        >
                            {errorMessage}
                        </Box>
                    </Box>
                )}

                <Text
                    mt={16}
                    opacity={0}
                    animation={`${fadeIn} 2s ease 4s forwards`}
                    fontFamily="mono"
                    fontSize={{ base: "8px", md: "12px" }}
                >
                    <Link href={`https://twitter.com/${twitterName}`} isExternal>
                        @{twitterName}
                    </Link>{" "}
                    <br />
                    Contract address at {" "}
                    <Link href={`https://etherscan.io/address/${process.env.GATSBY_CONTRACT_ADDRESS}`} isExternal>
                        {process.env.GATSBY_CONTRACT_ADDRESS}
                    </Link>
                </Text>
            </Box>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                scrollBehavior="inside"
                size="xl"
                closeOnOverlayClick={false}
            >
                <SendPresentModal currentAccount={currentAccount} />
            </Modal>
        </>
    );
};

export default IndexPage;
